<div class="barcode-scanner-livestream-overlay" [hidden]="!showScanner">
  <div
    class="barcode-scanner-livestream-overlay-content"
    [style.width]="width"
    [style.max-width]="maxWidth"
    [style.height]="height"
    [style.max-height]="maxHeight"
  >
    <div
      class="barcode-scanner-livestream-overlay-close"
      *ngIf="isStarted"
      (click)="hide()"
    >
      X
    </div>
    <barcode-scanner-livestream
      [type]="type"
      [deviceId]="deviceId"
      [config]="config"
      (valueChanges)="onValueChanges($event)"
      (started)="onStarted($event)"
    ></barcode-scanner-livestream>
  </div>
</div>
