<div class="barcode-scanner-container">
  <barcode-scanner-livestream
    [type]="['code_128', 'code_39', 'ean_8']"
    [errorFilter]="{ median: 0.08, threshold: 0.1 }"
    (valueChanges)="onValueChanges($event)"
    (started)="onStarted($event)"
  ></barcode-scanner-livestream>
</div>

<div class="barcode-value" [hidden]="!barcodeValue">
  {{ barcodeValue }}
</div>

{{productName}}
