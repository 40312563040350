<div class="barcode-scanner-container">
  <button type="button" (click)="startBarcodeScannerOverlay()">
    Scan codebar with overlay window
  </button>
  <barcode-scanner-livestream-overlay
    [type]="['code_128', 'code_39', 'ean_8']"
    (valueChanges)="onValueChanges($event)"
    (started)="onStarted($event)"
  ></barcode-scanner-livestream-overlay>
</div>

<div class="barcode-value" [hidden]="!barcodeValue">
  {{ barcodeValue }}
</div>
