<nav class="navbar navbar-expand-lg navbar-light bg-light" hidden>
  <div class="container">
    <a class="navbar-brand" href="#">ngx-barcode-scanner</a>
    <button
      class="navbar-toggler collapsed"
      (click)="isCollapsed = !isCollapsed"
      aria-expanded="false"
      type="button"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [collapse]="isCollapsed">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#"
            >Demo <span class="sr-only">(current)</span></a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container">
  <div class="row">
    <main class="col-12 py-md-3 pl-md-5">
      <h1>BarreCode test</h1>
      <div hidden>
        ngx-barcode-scanner is an Angular wrapper for
        <a href="https://serratus.github.io/quaggaJS/">quaggajs</a>. It provides
        2 components to read barcode with a camera in a livestream :
        <ul>
          <li>
            <a routerLink="livestream">barcode-scanner-livestream</a>: a simple
            livestream component, that can be integrated in any page or
            component
          </li>
          <li>
            <a routerLink="livestream-overlay"
              >barcode-scanner-livestream-overlay</a
            >: an overlay livestream component
          </li>
        </ul>
      </div>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
