import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { QuaggaJSResultObject } from '@ericblade/quagga2';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-barcode-scanner-livestream',
    templateUrl: './barcode-scanner-livestream-route.component.html',
    styleUrls: ['./barcode-scanner-livestream-route.component.scss']
})
export class BarcodeScannerLivestreamRouteComponent implements AfterViewInit {

    @ViewChild(BarcodeScannerLivestreamComponent)
    barcodeScanner: BarcodeScannerLivestreamComponent;

    barcodeValue: string;
  public productName: any;

    constructor(private httpClient: HttpClient) { }

    ngAfterViewInit(): void {
        this.barcodeScanner.start();
    }

    onValueChanges(result: QuaggaJSResultObject): void {
        console.log(result.codeResult.code);
        this.barcodeValue = result.codeResult.code;

        this.sendGetRequest(result.codeResult.code).subscribe((data: any) => {
          console.log(data);

          try {
           this.productName = data.product.product_name;
          } catch (error) {

          }


        });


    }

    onStarted(event): void {
        console.log('started', event);
    }



  public sendGetRequest(id): Observable<any> {
    return this.httpClient.get('/api/data.php?id=' + id);
  }

}
